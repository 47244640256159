import React from "react";
// import PropTypes from "prop-types"
import { talksDetails } from "../talksdetails/AllState.js";
import IndivSpeakers from "./indivspeakers";
import "./styles.scss";

const HasuraConSpeakers = () => {
  const speakersList = talksDetails.map((list, index) => {
    return <IndivSpeakers key={index} speakerDetails={list} />;
  });
  return (
    <section>
      <div className="containerWrapper">
        <div className="hasuraConSpeakersWrapper">
          <div className="hasuraConSpeakersListWrapper">{speakersList}</div>
        </div>
      </div>
    </section>
  );
};

export default HasuraConSpeakers;
