import React from "react";
import { Link } from "gatsby";
// import PropTypes from "prop-types"
import HasuraConSpeakers from "./hasuraconspeakers";
import HasuraConTalks from "./hasuracontalks";
import "../hasuracon/styles.scss";
import hasConLogo from "../hasuracon/images/has-con-logo.svg";
import pattern from "../hasuracon/images/pattern.svg";
import back from "../hasuracon/images/back.svg";
const SpeakersBanner = () => {
  return (
    <section
      id="agenda"
      className="commonSectionWrapper topBannerWrapperHasuraConRel addTopbannerPadd"
    >
      <div className="containerWrapper">
        <div className="topBannerWrapperHasuraCon">
          <div className="backHasuraConf">
            <Link to="/events/hasura-con-2020/">
              <img src={back} alt="back" />
              <span>Conference page</span>
            </Link>
          </div>
          <div className="frameCon removePaddBottom">
            <img src={hasConLogo} alt="Hasuracon20" />
          </div>
        </div>
        <HasuraConTalks />
        <HasuraConSpeakers />
      </div>
      <div className="patternImg talksImg">
        <img src={pattern} alt="Pattern" />
      </div>
    </section>
  );
};

export default SpeakersBanner;
