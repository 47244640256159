import React from "react";
// import PropTypes from "prop-types"
import "./styles.scss";

import useCases from "./images/use-cases.svg";
import tooling from "./images/tooling.svg";
import inside from "./images/inside.svg";
const talksState = [
  {
    icon: inside,
    title: "Use-cases",
    description:
      "Our users & community share stories of using & managing GraphQL & Hasura in production.",
  },
  {
    icon: tooling,
    title: "Tooling",
    description:
      "Discover the ecosystem of products & tooling that can enhance your Hasura experience.",
  },
  {
    icon: useCases,
    title: "Inside Hasura",
    description: "Hear from Hasura engineers about what we’re working on to push GraphQL forward.",
  },
];
const HasuraConTalks = () => {
  const talksList = talksState.map((list, index) => {
    return (
      <div key={index} className="col-md-4 col-sm-4 col-xs-12">
        <div className="hasuraConTalksList">
          <div className="talksIcon">
            <img src={list.icon} alt="Icon" />
          </div>
          <h3 className="smallParaHeaderCon">{list.title}</h3>
          <div className="bodyTextSmallCon">{list.description}</div>
        </div>
      </div>
    );
  });
  return (
    <section id="speakers" className="commonSectionWrapper">
      <div className="containerWrapper">
        <div className="hasuraConTalksWrapper">
          <div className="extraSmallBodyTextCon">22-24 June</div>
          <div className="bodyTextCon">Speakers</div>
          <h1 className="sectionHeaderCon">
            Hear from our users & the community about how they use GraphQL & Hasura
          </h1>
          <div className="hasuraConTalksListWrapper">{talksList}</div>
        </div>
      </div>
    </section>
  );
};

export default HasuraConTalks;
