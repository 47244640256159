import React from "react";
import Seo from "../../../components/seo";
import HasuraConHeader from "../../../components/hasuracon/hasuraconheader";
import "../../../components/hasuracon/styles.scss";
import SpeakersBanner from "../../../components/hasuracon/speakersbanner";
// import InterestedSpeaker from '../../../components/hasuracon/interestedspeaker'
import Footer from "../../../components/common/footer";
import HasuraConFooter from "../../../components/hasuracon/hasuraconfooter";
const ogImage = {
  ogImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-con-card.png",
};
const canonicalUrl = "https://hasura.io/events/hasura-con-2020/speakers/";
const Talks = props => (
  <div id="viewport" className="wd100 viewport">
    <div className="blueGradientBgColor">
      <Seo
        title="HasuraCon'20 | List of Speakers and Talks"
        description="List of talks for HasuraCon'20 . HasuraCon is a free, online conference. Join us for a week of talks and hand-on workshops about pushing the boundaries of GraphQL forward!"
        meta={ogImage}
        canonicalLink={canonicalUrl}
      />
      <HasuraConHeader location={props.location} />
      <SpeakersBanner />
      {/* <InterestedSpeaker/> */}
      <HasuraConFooter location={props.location} />
    </div>
    <Footer location={props.location} />
  </div>
);

export default Talks;
