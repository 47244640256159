import React, { Fragment } from "react";
// import PropTypes from "prop-types"
import { Link } from "gatsby";
const IndivSpeakers = ({ speakerDetails }) => {
  return (
    <Fragment>
      {speakerDetails.speakersList.map((list, index) => {
        return (
          <Fragment key={index}>
            {!list.noShow ? (
              <div className="col-md-4 col-sm-6 col-xs-12">
                <Link to={"/events/hasura-con-2020/talks/" + speakerDetails.url + "/"}>
                  <div className="hasuraConSpeakersList">
                    <div className="hasuraConSpeakersProfileImg">
                      <img src={list.img} alt={list.name} />
                    </div>
                    <div className="hasuraConSpeakersProfileBg">
                      <img src={list.profileBg} alt="profile bg" />
                    </div>
                    <div className="hasuraConSpeakersProfileWrapper hidden-xs">
                      <div className="profileFeaturesWrapper">
                        <div>
                          <div className={"profileFeatures " + list.textColor}>
                            {speakerDetails.title}
                          </div>
                          <div className={"profileDesignation bodyTextSmallCon " + list.textColor}>
                            {list.org}
                          </div>
                        </div>
                      </div>
                      <div className="profileTitleHeight">
                        <div className={"profileName bodyTextSmallCon " + list.textColor}>
                          {list.name}
                        </div>
                        <div className={"profileDesignation bodyTextSmallCon " + list.textColor}>
                          {list.designation}
                        </div>
                        {/*
                      <div className={'profileDesignation bodyTextSmallCon ' + list.textColor}>
                        {list.org}
                      </div>
                      */}
                      </div>
                    </div>
                    <div className="hasuraConSpeakersProfileWrapper visible-xs">
                      <div className="profileHeight">
                        <div className={"profileName bodyTextSmallCon " + list.textColor}>
                          {list.name}
                        </div>
                        <div className={"profileDesignation bodyTextSmallCon " + list.textColor}>
                          {speakerDetails.title}
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ) : null}
          </Fragment>
        );
      })}
    </Fragment>
  );
};
export default IndivSpeakers;
